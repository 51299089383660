import {useState, useEffect, useContext} from 'react';
import { Link, NavLink } from 'react-router-dom';
import i18n from '../translations/i18n';
import { useTranslation } from "react-i18next";
import LocaleContext from '../translations/LocaleContext.js'

function Header () {
  const { t } = useTranslation();
  const { locale } = useContext(LocaleContext);
  function changeLocale (l) {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  }
const [isActive, setIsActive] = useState(false);
const [isDropdown, setIsDropdown] = useState(false);
const handleClick = event => {
    setIsActive(current => !current);
  };
const handleDropdown = event => {
    setIsDropdown(current => !current);
  };
  useEffect(() => {
  const script = document.createElement('script');

  script.src = "/assets/js/main.js";
  script.async = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
}, []);

 

function scrollToTargetAdjusted() {
        var element = document.getElementById('pilar_usaha');
      var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });   
  }

  
return (
    <header id="header" className="fixed-top d-flex align-items-center header-transparent">
    <div className="container-fluid container-xl d-flex align-items-center justify-content-start">
  
      <div className="logo img-auto" >
        <NavLink to="/" onClick={() => window.location.reload()()}><img src="https://api.nbagroup.co.id/assets/img/NBA.png" alt="" className="img-fluid"/></NavLink>
      </div>
  
  
      <nav id="navbar" className={isActive ? 'navbar order-last order-lg-0 navbar-mobile' : 'navbar order-last order-lg-0'}>
        <ul>
          <li><NavLink to="/" onClick={() => window.location.reload()()} activeclassname="active">{t("home.header")}</NavLink></li>
          <li><Link to="/#pilar_usaha" onClick={scrollToTargetAdjusted} >{t("pilar_usaha.header")}</Link></li>
          <li><NavLink to="/tentang" activeclassname="active">{t("tentang.header")}</NavLink></li>
          <li><NavLink to="/berita" activeclassname="active">{t("berita.header")}</NavLink></li>
          <li><NavLink to="/karir" activeclassname="active">{t("karir.header")}</NavLink></li>
          <li className="dropdown" onClick={handleDropdown}><Link><span><i className="ri-translate-2"></i> {t("language")}</span> <i className="bi bi-chevron-down"></i></Link>
            <ul className={isDropdown ? 'dropdown-active' : ''} >
              <li><NavLink onClick={() => changeLocale('id')} >Bahasa Indonesia</NavLink>
              </li>
              <li><NavLink onClick={() => changeLocale('en')} >English</NavLink>
              </li>
            </ul>
          </li>
        </ul>
        <i className={isActive ? 'bi bi-x mobile-nav-toggle' : 'bi bi-list mobile-nav-toggle'} onClick={handleClick} ></i>
      </nav>
      
      
      
      </div>

  </header>
 
  );

};


export default Header;

