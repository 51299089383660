import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { ExternalLink } from "react-external-link";
import { useTranslation } from "react-i18next";

const Kaluna = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <main id="main">
          <div className="col-md-12 bg-image image">
            <img src="https://api.nbagroup.co.id/assets/img/kaluna.png" className="img-fluid imageContent" alt="" />
          </div>

        <section id="services" className="services section-bg">
          <div className="container" >
            <div className="section-title" style={{marginTop:"-30px"}}>
              <h2>Kaluna</h2>
            </div>
            <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ marginTop: "30px" }}>
              <div className="col-md-10 bg-image ">
                <p style={{ textAlign: "left", marginTop: "-25px", fontWeight: "16px" }}>
                {t("kaluna1")}
                </p>
                <p style={{ textAlign: "left", marginTop: "5px", fontSize: "16px" }}>
                  {t("kaluna2")}
                </p>
                <p style={{ textAlign: "left", marginTop: "5px", fontWeight: "16px" }}>
                  {t("kaluna3")}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="https://api.nbagroup.co.id/assets/img/kaluna1.png" className="img-fluid center-block" alt="" />
                </div>
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="https://api.nbagroup.co.id/assets/img/kaluna2.png" className="img-fluid center-block" alt="" />
                </div>
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="https://api.nbagroup.co.id/assets/img/kaluna3.png" className="img-fluid center-block" alt="" />
                </div>
              </div>
            </div>
            <div class="position-absolute top-100 start-50 translate-middle">
              <ExternalLink href="whatsapp://send?phone=+6287847634827" className="btn" type="button" style={{ backgroundColor: "orange", color: "white" }}>
                {t("pesan")}
              </ExternalLink>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "0" }}>
              <h2>{t("hubungi")}</h2>
            </div>
              <div className="row justify-content-center align-items-center" >
              <div className="col-md-7">
              <div className="row  justify-content-center" >
                <div className="col-md-3 text-center align-items-center">
                  <ExternalLink href="https://www.instagram.com/kaluna_coffee/" target="_blank" className="instagram">
                 <h5> <i className="bx bxl-instagram"></i> kaluna_coffee </h5>
                </ExternalLink> 
                </div>
                <div className="col-md-3 text-center align-items-center" >
                  <ExternalLink href="whatsapp://send?phone=+6287847634827" target="_blank" className="instagram">
                 <h5> <i className="bx bxl-whatsapp"></i> 087847634827 </h5>
                </ExternalLink> 
                </div>
                <div className="col-md-4 "  >
                  <ExternalLink href="https://www.kaluna.co.id/" target="_blank" className="instagram">
                 <h5><i className="bx bx-globe"></i> www.kaluna.co.id</h5>
                </ExternalLink> 
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default Kaluna;
