import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Kiosery = () => {
  return (
    <>
      <Header />

      <main id="main">
        <div className="row justify-content-center">
          <div className="col-md-12 bg-image image">
            <img src="https://api.nbagroup.co.id/assets/img/Kiosery.png" className="img-fluid centerImage" alt="" />
          </div>
        </div>

        <section id="services" className="services section-bg">
          <div className="container" >
            <div className="section-title" style={{marginTop:"-30px"}}>
              <h2>Kiosery</h2>
            </div>
            <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ marginTop: "30px" }}>
              <div className="col-md-10 bg-image ">
                <h5 style={{ textAlign: "left", marginTop: "-25px", fontWeight: "normal" }}>
                  Kiosery merupakan perusahaan rantai pasok yang menyediakan solusi terhadap pendistribusian produk yang paling bermanfaat dan terpercaya untuk Masyarakat Indonesia.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="https://api.nbagroup.co.id/assets/img/Kiosery1.png" className="img-fluid center-block" alt="" />
                </div>
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="https://api.nbagroup.co.id/assets/img/Kiosery2.png" className="img-fluid center-block" alt="" />
                </div>
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="https://api.nbagroup.co.id/assets/img/Kiosery3.png" className="img-fluid center-block" alt="" />
                </div>
              </div>
            </div>
            <div class="position-absolute top-100 start-50 translate-middle">
              <button class="btn" type="button" style={{ backgroundColor: "orange", color: "white" }}>
                Pesan Sekarang
              </button>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "0" }}>
              <h2>Hubungi Kami</h2>
            </div>
            <div class="position-absolute top-100 start-50 translate-middle">
              <img src="https://api.nbagroup.co.id/assets/img/Link.png" className="img-fluid center-block" alt="" />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default Kiosery;
