import React from 'react';
import Header from './Header';
import Footer from './Footer';
import moment from "moment";
import 'moment/locale/id' 
moment.locale('id')


const ComingSoon = () => {


  return (
<>

    
  <Header/>



  <main id="main">
    
      <div className="col-md-12 bg-image image"   >
        <img src="https://api.nbagroup.co.id/assets/img/coming-soon.png" className="img-fluid imageContent" alt="" />
      </div>


    <section id="services" className="services section-bg">
      <div className="container" >
        <div className="section-title" style={{marginTop:"-30px"}}>
          <h2>Coming Soon</h2>
        </div>

       <div className="row justify-content-center align-items-center no-gutters clients-wrap clearfix" data-aos="fade-up">

        </div>

      </div>

    </section>

  </main>
  <Footer/>
</>
  );
}
export default ComingSoon;